import React, { useEffect } from 'react';
import Hotjar from '@hotjar/browser';

const HotjarComponent: React.FC = () => {
  useEffect(() => {
    const siteId = Number(process.env.REACT_APP_HOTJAR_ID ?? '0');
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return null;
};

export default HotjarComponent;

