import React, { useState, useEffect } from 'react';


interface MenuItem {
  descricao: string;
  valor?: string[];
  copo?: string;
  garrafa?: string;
  nome?: string;
  meia?: string;
  grande?: string;
}

interface MenuProps {
  title: string;
  filename: string;
  type?: 'default' | 'wine' | 'pizza';
}

const MenuSection: React.FC<MenuProps> = ({ title, filename, type = 'default' }) => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://menu.jsmotta.com.br/tabelas/${filename}`, {
            headers: {
                'Cache-Control': 'no-cache'
            }
        });      
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedData = data.sort((a:any, b:any) => a.descricao.localeCompare(b.descricao));
        setItems(sortedData);
  
        setItems(data);
        setLoading(false);
      } catch (err) {
        setError('Falha ao carregar os itens do menu');
        setLoading(false);
      }
    };

    fetchData();
  }, [filename]);

  if (loading) return <div className="loading">Carregando...</div>;
  if (error) return <div className="error">{error}</div>;

  const renderDefaultMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="menu-item">
          <div className="item-details">
            <div className="item-name">{item.descricao}</div>
          </div>
          <div className="item-price">
            {item.valor && item.valor[0]}
          </div>
        </div>
      ))}
    </div>
  );

  const renderWineMenu = () => (
    <table className="wine-table">
      <thead>
        <tr>
          <th>Vinho</th>
          <th>Copo</th>
          <th>Garrafa</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.descricao}</td>
            <td>{item.copo}</td>
            <td>{item.garrafa}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderPizzaMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="pizza-item">
          <div className="pizza-name">{item.nome}</div>
          <div className="pizza-description">{item.descricao}</div>
          <div className="pizza-prices">
            {item.meia && <span>Meia: {item.meia}</span>}
            {item.grande && <span>Grande: {item.grande}</span>}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <section className="menu-section">
      <h2 className="section-title">{title}</h2>
      {type === 'wine' && renderWineMenu()}
      {type === 'pizza' && renderPizzaMenu()}
      {type === 'default' && renderDefaultMenu()}
    </section>
  );
};

const MenuCompleto: React.FC = () => {

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const currentDay = currentDate.getDate();
  const isHalloweenOrDayAfter = (currentMonth === 10 && currentDay === 31) || (currentMonth === 11 && currentDay === 1);


  return (
    <div className="menu-container">
      <MenuSection title="Happy Hour" filename="happyHour.json" />
      <MenuSection title="Combos Promocionais" filename="combos.json" />
      <MenuSection title="Bebidas" filename="bebidas.json" />
      <MenuSection title="But first coffee" filename="coffee.json" />
      <MenuSection title="Cidras e Cervejas" filename="cidraECervejas.json" />
      <MenuSection title="Cocktail" filename="cocktail.json" />
      <MenuSection title="Caipirinhas" filename="caipirinhas.json" />
      <MenuSection title="Gin" filename="gin.json" />
      <MenuSection title="Wine Not" filename="wineNot.json" type="wine" />
      <MenuSection title="Sangrias" filename="sangrias.json" />
      <MenuSection title="Doses" filename="doses.json" />
      {isHalloweenOrDayAfter && (<MenuSection title="Especial Halloween" filename="halloween.json" />)}
      <MenuSection title="Bites" filename="bites.json" />
      <MenuSection title="Entradas" filename="entradas.json" />
      <MenuSection title="Sandes" filename="sandes.json" />
      <MenuSection title="Tostas" filename="tostas.json" />
      <MenuSection title="Salgados" filename="salgados.json" />
      <MenuSection title="Pizzas" filename="pizzas.json" type="pizza" />
    </div>
  );
};

export default MenuCompleto;