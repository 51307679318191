import React, { useState, useEffect } from "react";

interface MenuItem {
  descricao: string;
  valor?: string[];
  copo?: string;
  garrafa?: string;
  nome?: string;
  meia?: string;
  grande?: string;
}

interface MenuProps {
  title: string;
  filename: string;
  type?: "default" | "wine" | "pizza";
}

const MenuSection: React.FC<MenuProps> = ({
  title,
  filename,
  type = "default",
}) => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await  fetch('https://menu.jsmotta.com.br/tabelas/' + filename + '?' + Date.now(), { cache: 'no-store' })

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedData = data.sort((a: any, b: any) =>
          a.descricao.localeCompare(b.descricao)
        );
        setItems(sortedData);

        setItems(data);
        setLoading(false);
      } catch (err) {
        setError("Falha ao carregar os itens do menu");
        setLoading(false);
      }
    };

    fetchData();
  }, [filename]);

  if (loading) return <div className="loading">Carregando...</div>;
  if (error) return <div className="error">{error}</div>;

  const renderDefaultMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="menu-item">
          <div className="item-details">
            <div className="item-name">{item.descricao}</div>
          </div>
          <div className="item-price">{item.valor && item.valor[0]}</div>
        </div>
      ))}
    </div>
  );

  const renderWineMenu = () => (
    <table className="wine-table">
      <thead>
        <tr>
          <th>Vinho</th>
          <th>Copo</th>
          <th>Garrafa</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.descricao}</td>
            <td>{item.copo}</td>
            <td>{item.garrafa}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderPizzaMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="pizza-item">
          <div className="pizza-name">{item.nome}</div>
          <div className="pizza-description">{item.descricao}</div>
          <div className="pizza-prices">
            {item.meia && <span>Meia: {item.meia}</span>}
            {item.grande && <span>Grande: {item.grande}</span>}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <section className="menu-section">
      <h2 className="section-title">{title}</h2>
      {type === "wine" && renderWineMenu()}
      {type === "pizza" && renderPizzaMenu()}
      {type === "default" && renderDefaultMenu()}
    </section>
  );
};

const MenuCompleto: React.FC = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const currentDay = currentDate.getDate();
  const isHalloweenOrDayAfter =
    (currentMonth === 10 && currentDay === 31) ||
    (currentMonth === 11 && currentDay === 1);

  return (
    <div className="menu-container">
      <MenuSection title="Happy Hour (Cervejas: 17 às 20h - Cocktails: 20 às 21h)" filename={"happ" + "yHour.json"} />
      <MenuSection title="Combos Promocionais" filename={"comb" + "os.json"} />
      <MenuSection title="Bebidas" filename={"beb" + "idas.json"} />
      <MenuSection title="But first coffee" filename={"cof" + "fee.json"} />
      <MenuSection
        title="Cidras e Cervejas"
        filename={"cidraE" + "Cervejas.json"}
      />
      <MenuSection title="Cocktail" filename={"cock" + "tail.json"} />
      <MenuSection title="Caipirinhas" filename={"caipi" + "rinhas.json"} />
      <MenuSection title="Gin" filename={"g" + "in.json"} />
      <MenuSection
        title="Wine Not"
        filename={"wine" + "Not.json"}
        type="wine"
      />
      <MenuSection title="Sangrias" filename={"san" + "grias.json"} />
      <MenuSection title="Doses" filename={"do" + "ses.json"} />
      {isHalloweenOrDayAfter && (
        <MenuSection
          title="Especial Halloween"
          filename={"hallow" + "een.json"}
        />
      )}
      <MenuSection title="Bites" filename={"bi" + "tes.json"} />
      <MenuSection title="Entradas" filename={"en" + "tradas.json"} />
      <MenuSection title="Sandes" filename={"san" + "des.json"} />
      <MenuSection title="Tostas" filename={"tos" + "tas.json"} />
      <MenuSection title="Salgados" filename={"sal" + "gados.json"} />
      <MenuSection title="Pizzas" filename={"pi" + "zzas.json"} type="pizza" />
    </div>
  );
};

export default MenuCompleto;
