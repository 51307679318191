import React, { useState, useEffect } from "react";

interface MenuItem {
  descricao: string;
  valor?: string[];
  copo?: string;
  garrafa?: string;
  nome?: string;
  meia?: string;
  grande?: string;
}

interface MenuProps {
  title: string;
  categoria: string;
  type?: "default" | "wine" | "pizza";
}

const MenuSection: React.FC<MenuProps> = ({
  title,
  categoria,
  type = "default",
}) => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.menu.jsmotta.com/api/v1/mnu/OpenMenu/GetJSon?categoia=" + categoria,
          { cache: "no-store" }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedData = data.sort((a: any, b: any) =>
          a.descricao.localeCompare(b.descricao)
        );
        setItems(sortedData);

        setItems(data);
        setLoading(false);
      } catch (err) {
        setError("Falha ao carregar os itens do menu");
        setLoading(false);
      }
    };

    fetchData();
  }, [categoria]);

  if (loading) return <div className="loading">Carregando...</div>;
  if (error) return <div className="error">{error}</div>;

  const renderDefaultMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="menu-item">
          <div className="item-details">
            <div className="item-name">{item.descricao}</div>
          </div>
          <div className="item-price">{item.valor && item.valor[0]}</div>
        </div>
      ))}
    </div>
  );

  const renderWineMenu = () => (
    <table className="wine-table">
      <thead>
        <tr>
          <th>Vinho</th>
          <th>Copo</th>
          <th>Garrafa</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.descricao}</td>
            <td>{!item.copo?.startsWith("0,00") && (item.copo)}</td>
            <td>{!item.garrafa?.startsWith("0,00") && (item.garrafa)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderPizzaMenu = () => (
    <div>
      {items.map((item, index) => (
        <div key={index} className="pizza-item">
          <div className="pizza-name">{item.nome}</div>
          <div className="pizza-description">{item.descricao}</div>
          <div className="pizza-prices">
            {item.meia && <span>Meia: {!item.meia.startsWith("0,00") && (item.meia)}</span>}
            {item.grande && <span>Grande: {!item.grande.startsWith("0,00") && (item.grande)}</span>}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <section className="menu-section">
      <h2 className="section-title">{title}</h2>
      {type === "wine" && renderWineMenu()}
      {type === "pizza" && renderPizzaMenu()}
      {type === "default" && renderDefaultMenu()}
    </section>
  );
};

const MenuCompleto: React.FC = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const currentDay = currentDate.getDate();
  const isHalloweenOrDayAfter =
    (currentMonth === 10 && currentDay === 31) ||
    (currentMonth === 11 && currentDay === 1);

  return (
    <div className="menu-container">
      <MenuSection
        title="Happy Hour (Cervejas: 17 às 20h - Cocktails: 20 às 21h)"
        categoria="happyHour"
      />
      <MenuSection title="Combos Promocionais" categoria="combos" />
      <MenuSection title="Bebidas" categoria="bebidas" />
      <MenuSection title="But first coffee" categoria="Café" />
      <MenuSection title="Cidras e Cervejas" categoria="Cervejas" />
      <MenuSection title="Cocktail" categoria="cocktails" />
      <MenuSection title="Caipirinhas" categoria="caipirinhas" />
      <MenuSection title="Gin" categoria="gin" />
      <MenuSection title="Wine Not" categoria="Vinhos" type="wine" />
      <MenuSection title="Sangrias" categoria="sangrias" />
      <MenuSection title="Doses" categoria="doses" />
      {isHalloweenOrDayAfter && (
        <MenuSection title="Especial Halloween" categoria="halloween" />
      )}
      <MenuSection title="Bites" categoria="bites" />
      <MenuSection title="Entradas" categoria="entradas" />
      <MenuSection title="Sandes" categoria="sandes" />
      <MenuSection title="Tostas" categoria="tostas" />
      <MenuSection title="Salgados" categoria="salgados" />
      <MenuSection title="Pizzas" categoria="Pizzas" type="pizza" />
    </div>
  );
};

export default MenuCompleto;
